<template>
    <vue-popover
        class="submenu"
        p-popover-inner-class="submenu__body"
        p-placement="top"
    >
        <template v-slot:header>
            <span class="submenu__icon tooltip-target"/>
        </template>
        <template v-slot:body>
            <ul class="submenu__links">
                <li class="submenu__item">
                    <router-link :to="`/courses/${$route.params.course_id}/${moduleId}/test/${test.id}/edit`">
                        Редактировать
                    </router-link>
                </li>
                <li class="submenu__item submenu__item--delete">
                    <a href="javascript:void(0)" @click="removeLesson">Удалить</a>
                </li>
            </ul>
        </template>
    </vue-popover>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "Submenu",
  components: {
      VuePopover : () => import('@/components/VuePopover')
  },
  props: {
    test: {
      required: true
    },
    moduleId: {
      required: true
    }
  },
  methods: {
    removeLesson () {
      eventBus.$emit('remove-test', this.test)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.trigger {
    display: flex !important;
}

.submenu {
    cursor: pointer;

    &:hover .submenu__icon {
        background-image: $rubric-menu-icon-active;
    }

    &__icon {
        display: flex;
        background-image: $rubric-menu-icon;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        min-width: 18px !important;
        min-height: 18px !important;
        width: 18px;
        height: 18px;
        margin-top: 5px;

        @include below($mobile) {
            transform: rotate(90deg);
            margin-right: -6px;
        }
    }

    &__links {
        padding: 0;
        list-style-type: none;
        width: 100%;
        margin-bottom: 0;
        border-radius: 10px;
        padding: 14px 16px;
    }

    &__item {
        margin: 5px 0;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;

        a {
            color: $black;
        }

        &--delete a {
            color: #ff0000;
        }
    }

    &__item &:hover a {
        color: $black;
    }

    a {
        padding: 5px 15px;
        height: 100%;
        width: 100%;
        display: flex;
        color: $black;
        cursor: pointer;

        &:first-child {
            padding-top: 10px;
        }

        &:last-child {
            padding-bottom: 10px;
        }
    }
}
</style>

